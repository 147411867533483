import { HeurekaElementFactory } from "../util/HeurekaElementFactory";

const STATIC_POPULAR_VALUES_ID = "heureka_popularFacetValues";
export class StaticPopularFacetValuesMap {
  private static readonly factory = HeurekaElementFactory.byId(STATIC_POPULAR_VALUES_ID, StaticPopularFacetValuesMap);

  /*               */
  constructor(private readonly elem: HTMLElement) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return StaticPopularFacetValuesMap.factory.pick(undefined, rootElement);
  }

  static prepareAll(fragment?: DocumentFragment, initialLoad = false) {
    StaticPopularFacetValuesMap.template(fragment)?.prepare(initialLoad, fragment);
  }

  private prepare(initialLoad: boolean, fragment?: DocumentFragment) {
    const oldTemplate = StaticPopularFacetValuesMap.template();
    if (oldTemplate) {
      this.popularFacetValuesMap = oldTemplate.popularFacetValuesMap || {};
    }
    if (initialLoad) {
      this.popularFacetValuesMap = StaticPopularFacetValuesMap.template(fragment)?.popularFacetValuesMap || {};
    }
  }

  get popularFacetValuesMap() {
    return JSON.parse(this.elem.dataset.popularValuesJson);
  }
  set popularFacetValuesMap(map: Record<string, string[]>) {
    this.elem.dataset.popularValuesJson = JSON.stringify(map);
  }
}
